import 'babel-polyfill'
import 'js/polyfills/customEvent'
import 'js/polyfills/closest'
import objectFitImages from 'object-fit-images'
import lazysizes from 'lazysizes'
/*import 'lazysizes/plugins/blur-up/ls.blur-up'

lazysizes.cfg.blurupMode = 'auto'*/

// =utils
import 'js/utils/breakpoints'
import 'js/utils/detectTouch'
import 'js/utils/ie11'
import 'js/utils/revealAnimation'

if (document.querySelector('.js-notification-popup')) {
  import(
    /* webpackChunkName: "notificationPopup" */ 'js/components/notificationPopup'
  )
}

if (document.querySelector('[data-height="equal"]')) {
  import(/* webpackChunkName: "utilEqualHeight" */ 'js/utils/equalHeight')
}

// debug mode
if (document.querySelector('body').classList.contains('-debug')) {
  import(/* webpackChunkName: "debugger" */ 'js/utils/debugger')
}

objectFitImages()

//===============================================================/
//  =components
//===============================================================/

if (document.querySelector('.cookie-consent')) {
  import(
    /* webpackChunkName: "compCookieConsent" */ 'js/components/cookieConsent'
  )
}
if (document.querySelector('.back-to-top__wrapper')) {
  import(/* webpackChunkName: "backToTop" */ 'js/components/backToTop')
}
//if (document.querySelector('.share-icon__share-wrapper')) {
//import(/* webpackChunkName: "shareIcon" */ 'js/components/shareIcon')
//}
if (document.querySelector('.tabbed-nav')) {
  import(/* webpackChunkName: "tabbedContent" */ 'js/components/tabbedContent')
}
if (document.querySelector('.form-filter')) {
  import(/* webpackChunkName: "filter" */ 'js/components/filter')
}
if (document.querySelector('.js-form')) {
  import(/* webpackChunkName: "formShortcode" */ 'js/components/form-shortcode')
}
if (document.querySelector('.js-video-embed__block')) {
  import(/* webpackChunkName: "iframePlayer" */ 'js/components/iframePlayer')
}
if (document.querySelector('.js-smooth-scroll-to')) {
  import(
    /* webpackChunkName: "smoothScrollTo" */ 'js/components/smoothScrollTo'
  )
}
if (document.querySelector('.js-age-consent-popup-active')) {
  import(
    /* webpackChunkName: "ageConsentPopup" */ 'js/components/ageConsentPopup'
  )
}

if (document.querySelector('.js-flipping-card')) {
  import(/* webpackChunkName: "flippingCard" */ 'js/blocks/flippingCards')
}
if (document.querySelector('.b-page-section-links-block')) {
  import(
    /* webpackChunkName: "stickySectionNav" */ 'js/blocks/stickySectionsNav'
  )
}

//===============================================================/
//  =blocks
//===============================================================/

if (
  document.querySelector('.b-accordion') ||
  document.querySelector('.b-elemental-list-accordion')
) {
  import(/* webpackChunkName: "accordion" */ 'js/blocks/accordion')
}
if (document.querySelector('.b-gallery')) {
  import(/* webpackChunkName: "gallery" */ 'js/blocks/gallery')
}
if (document.querySelector('.b-key-statistics')) {
  import(/* webpackChunkName: "keyStatistics" */ 'js/blocks/keyStatistics')
}
if (document.querySelector('.b-video-embed')) {
  import(/* webpackChunkName: "videoEmbed" */ 'js/blocks/videoEmbed')
}
if (document.querySelector('.b-hero-carousel-banner')) {
  import(
    /* webpackChunkName: "heroCarouselBanner" */ 'js/blocks/heroCarouselBanner'
  )
}
if (document.querySelector('.b-two-column-media-and-content')) {
  import(
    /* webpackChunkName: "twoColumnMediaAndContent" */ 'js/blocks/twoColumnMediaAndContent'
  )
}
if (document.querySelector('.b-two-column-content-and-data')) {
  import(
    /* webpackChunkName: "twoColumnContentAndData" */ 'js/blocks/twoColumnContentAndData'
  )
}
if (document.querySelector('.b-two-column-image-and-content')) {
  import(
    /* webpackChunkName: "twoColumnImageAndContent" */ 'js/blocks/twoColumnImageAndContent'
  )
}
if (document.querySelector('.b-elemental-list-tabs')) {
  import(/* webpackChunkName: "tabbedContent" */ 'js/blocks/tabbedContent')
}
if (document.querySelector('.b-graph')) {
  import(/* webpackChunkName: "graph" */ 'js/blocks/graph')
}
if (document.querySelector('.b-location')) {
  import(/* webpackChunkName: "locations" */ 'js/blocks/locations')
}
if (document.querySelector('.b-feature-banner-pouring-bottle')) {
  import(
    /* webpackChunkName: "featureBannerPouringBottle" */ 'js/blocks/featureBannerPouringBottle'
  )
}
if (document.querySelector('.b-feature-banner-filling-bottle')) {
  import(
    /* webpackChunkName: "featureBannerFillingBottle" */ 'js/blocks/featureBannerFillingBottle'
  )
}
if (document.querySelector('.b-timeline')) {
  import(/* webpackChunkName: "timeline" */ 'js/blocks/timeline')
}
if (document.querySelector('.js-twitter-feed-swiper')) {
  import(/* webpackChunkName: "twitterFeed" */ 'js/blocks/twitterFeed')
}
if (document.querySelector('.js-circular-carousel')) {
  import(
    /* webpackChunkName: "circularCarousel" */ 'js/blocks/circularCarousel'
  )
}
if (document.querySelector('.js-accordion-carousel')) {
  import(
    /* webpackChunkName: "accordionCarousel" */ 'js/blocks/accordionCarousel'
  )
}

if (document.querySelector('.b-annual-report-2023-download-button')) {
  import(
    /* webpackChunkName: "annualReport2023DownloadButton" */ 'js/blocks/annualReport/2023/downloadButton'
  )
}

if (document.querySelector('.b-annual-report-2023-content-and-accordion')) {
  import(
    /* webpackChunkName: "annualReport2023ContentAndAccordion" */ 'js/blocks/annualReport/2023/contentAndAccordion'
  )
}

if (document.querySelector('.b-annual-report-2023-video-embed')) {
  import(
    /* webpackChunkName: "annualReport2023VideoEmbed" */ 'js/blocks/annualReport/2023/videoEmbed'
  )
}

//===============================================================/
//  =layout
//===============================================================/

if (document.querySelector('.header')) {
  import(/* webpackChunkName: "layoutNav" */ 'js/layouts/nav')
}
if (document.querySelector('.js-nav-block-subs')) {
  import(/* webpackChunkName: "navBlockSub" */ 'js/layouts/navBlockSub')
}
if (document.querySelector('.footer')) {
  import(/* webpackChunkName: "layoutFooter" */ 'js/layouts/footer')
}
if (document.querySelector('.search-overlay__wrapper')) {
  import(/* webpackChunkName: "layoutSearch" */ 'js/layouts/search')
}
if (document.querySelector('.js-utility-nav-overlay')) {
  import(
    /* webpackChunkName: "layoutUtilityOverlay" */ 'js/layouts/utilityOverlay'
  )
}
import(/* webpackChunkName: "wysiwyg" */ 'js/layouts/wysiwyg')

//===============================================================/
//  =pages
//===============================================================/

if (document.querySelector('.js-video')) {
  import(/* webpackChunkName: "videoList" */ 'js/pages/videoList')
}
if (document.querySelector('.js-resource-list')) {
  import(/* webpackChunkName: "resourceList" */ 'js/pages/resourceList')
}
if (document.querySelector('.js-product-list')) {
  import(/* webpackChunkName: "productList" */ 'js/pages/productList')
}
